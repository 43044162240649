import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export interface IButtonProps {
	children: ReactNode;
	color?: string;
	size?: string;
	outline?: boolean;
	pill?: boolean;
	loading?: boolean;
	disabled?: boolean;
	block?: boolean;
	submit?: boolean;
	hollow?: boolean;
	dropdown?: boolean;
	className?: string;
	onClick?: () => void;
}

export default class Button extends Component<IButtonProps> {
	static size = {
		NORMAL: undefined,
		BIG: 'big',
		SMALL: 'small',
	};

	static color = {
		PRIMARY: 'Primary',
		SECONDARY: 'Secondary',
		TERNARY: 'Ternary',
		DIMMED: 'Dimmed',
		FACEBOOK: 'Facebook',
		TWITTER: 'Twitter',
	};

	render() {
		const { size, color, loading, disabled, pill, block, submit, hollow, dropdown, onClick } = this.props;

		const buttonType = hollow ? 'buttonHollow' : 'button';

		const buttonClassNames = classNames(
			{
				...(size ? { [styles[size]]: size } : {}),
				[styles[buttonType + color]]: color,
				[styles.pill]: pill,
				[styles.block]: block,
				[styles.dropdown]: dropdown,
			},
			styles[buttonType],
			this.props.className
		);

		const type = submit ? 'submit' : undefined;

		return (
			<button disabled={loading || disabled} onClick={onClick} type={type} className={buttonClassNames}>
				{loading ? <div className={styles.spinner}></div> : this.props.children}
			</button>
		);
	}
}
